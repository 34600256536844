import { Injectable } from '@angular/core';
import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';
import { Router } from '@angular/router';
import { Observable, Subscription, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { RoutesI } from '../models/request.interface';
import { HttpClient } from '@angular/common/http';
import { Componente } from '../models/menuApp.interface';
import { RequestRoutePage } from '../passenger/request-route/request-route.page';
import { NavController } from '@ionic/angular';
import { ReservationPage } from '../passenger/reservation/reservation.page';

@Injectable({
  providedIn: 'root'
})
export class DataService {
  private solicitudesCollection: AngularFirestoreCollection<RoutesI>;
  private solicitudes: Observable<RoutesI[]>;

  //datosDEvuelta: Observable<any[]>;

  datosDEvuelta: any[] =[];

  datosParaEnvio: any[] =[];
  

  constructor( private db: AngularFirestore, private http: HttpClient, private nav: NavController, /*private reservaciones: ReservationPage*/) { 
    
  }

  getSolicitudes(){
    //return this.solicitudes;
    console.log("entro al metodo del servicio de consulta de rutas por parametros");
    /* return this.solicitudesCollection.doc(solicitud); */
    return this.solicitudes
  }

  getSolicitudesEspecific(destinos: string, origens: string, date: string){
    //return this.solicitudes;
    console.log("entro al metodo del servicio de consulta de rutas por parametros", destinos, origens);

    var gato = this.db.collection('rutas', ref => ref.where('destino', '==', destinos));
    console.log('mramos que nos trae ', gato);

    var dateFormat = date.split('T')[0]; 

    console.log('soy el valor de fecha que esta llegando antes de hacer la consulta', dateFormat);

    
    
    //ESTA LINEA ESTA OK
    return this.db.collection('rutas', ref => ref.where('destino', '==', destinos)
           .where('origen', '==', origens)
           .where('fecha', '==', dateFormat))
           .valueChanges()
           .subscribe(res => {
            console.log('valores encontrados en funcion getSolicitudesEspecific', res);
           
            this.datosDEvuelta = res;

            console.log('valores encontrados en funcion getSolicitudesEspecific DatosDevuelta', this.datosDEvuelta);


            
            //this.enviaDatos.reciveDatos(res);
            
            /*const queryParams: any = {};
            queryParams.miArray = JSON.stringify(res);

            const navigationExtras: any = {
              queryParams
            }

            console.log('hola hola hola', queryParams);*/

            //this.nav.navigateForward(['/reservation',{res: res}]);
            
            //ESTA DE AQUI ABAJO FUNCIONA
            //this.nav.navigateForward(['/reservation', {res: JSON.stringify(res)} ]); 

            //this.nav.navigateForward(['/reservation', res]); 

            this.nav.navigateForward(['/reservation'], { state: { res: res } });



            

            this.datosParaEnvio = res;
            //this.nav.navigateForward(['/reservation',{queryParams: navigationExtras}]);

            this.processDataSend();


            //return res;

            //this.datosDEvuelta(=>)

            

            //return this.getReservations();
          })

  }


  processDataSend(){
    const test = this.datosParaEnvio
    console.log('metodo processDataSend', this.datosParaEnvio);
    //this.nav.navigateForward(['/reservation', {res: this.datosParaEnvio} ]); 
    //this.nav.navigateForward(['/reservation', test]); 
  }

 


  getMenuOpts(){
    return this.http.get<Componente[]>('/assets/data/menu-opts.json');
  }

}

function query(query: any, any: any) {
  throw new Error('Function not implemented.');
}

