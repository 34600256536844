import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './shared/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  {
    path: 'home',
    loadChildren: () => import('./passenger/home/home.module').then( m => m.HomePageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'perfil',
    loadChildren: () => import('./passenger/perfil/perfil.module').then( m => m.PerfilPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'customer-support',
    loadChildren: () => import('./passenger/customer-support/customer-support.module').then( m => m.CustomerSupportPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'payments',
    loadChildren: () => import('./passenger/payments/payments.module').then( m => m.PaymentsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'my-trips',
    loadChildren: () => import('./passenger/my-trips/my-trips.module').then( m => m.MyTripsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'request-route',
    loadChildren: () => import('./passenger/request-route/request-route.module').then( m => m.RequestRoutePageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'parcels',
    loadChildren: () => import('./passenger/parcels/parcels.module').then( m => m.ParcelsPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then( m => m.LoginPageModule)
  },
  {
    path: 'register',
    loadChildren: () => import('./register/register.module').then( m => m.RegisterPageModule)
  },
  {
    path: 'verify-email',
    loadChildren: () => import('./verify-email/verify-email.module').then( m => m.VerifyEmailPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'map',
    loadChildren: () => import('./passenger/map/map.module').then( m => m.MapPageModule),
    canActivate:[AuthGuard]
  },
  {
    path: 'reservation',
    loadChildren: () => import('./passenger/reservation/reservation.module').then( m => m.ReservationPageModule)
  },
  {
    path: 'confirm-reservation',
    loadChildren: () => import('./passenger/confirm-reservation/confirm-reservation.module').then( m => m.ConfirmReservationPageModule)
  },

];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
